/*
###############################################################################
##
## @file:			drupal.css
## @description:	Defines custom Drupal styling
##
###############################################################################


[Table of contents]

1. TABS
2. OVERLAY

###############################################################################
*/


/* =========================================================================
 * 1. TABS
 * ========================================================================= */

#content .tabs {
	/*height:25px;*/
	width:100%;
	margin: 10px 0 20px 0;
	padding: 0;
  *zoom: 1;
	/*border-bottom: solid 1px #ccc;
	border-width: 0 0 1px 0;*/
}

#content ul:before.tabs,
#content ul:after.tabs {
	clear: both;
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
	content: '\0020';
}

#content ul:before.tabs {
	clear: both;
}

#content .tabs li {
	display: block;
	width:auto;
	float: left;
	list-style: none;
	height: 40px;
}
#content .tabs li {
  margin:0;
}
#content .tabs a	{
	display: block;
	padding: 0 20px;
	height: 40px;
	text-decoration: none;
	border: solid 1px #ccc;
	border-width: 1px 0 1px 1px;
	background: #f3f3f3;
	line-height: 38px;
	color: #444;
}

	#content .tabs a:hover {
		background: #e5e5e5;
		color: #000;
	}

#content .tabs .active a	{
	position: relative;
	top: -5px;
	padding-top: 5px;
  height: 45px;
	margin: 0 -1px 0 0;
	background: #fff;
	border-bottom: none;
	border-width: 1px;
	border-bottom-color: #fff;
	border-top-right-radius: 5px;
	-moz-border-top-right-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-khtml-border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	-moz-border-top-left-radius: 5px;
	-webkit-border-top-left-radius: 5px;
	-khtml-border-top-left-radius: 5px;
	color: #000;
}

#content .tabs li:first-child a {
	border-top-left-radius: 5px;
	-moz-border-top-left-radius: 5px;
	-webkit-border-top-left-radius: 5px;
	-khtml-border-top-left-radius: 5px;
}

#content .tabs li:last-child a {
	border-width: 1px 1px 1px 1px;
	border-top-right-radius: 5px;
	-moz-border-top-right-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-khtml-border-top-right-radius: 5px;
}


#content .tabs.secondary {
  clear: both;
  padding-top: 10px;
  font-size: 0.85em;
}
#content .tabs.secondary a,
#content .tabs.secondary .active a {
  position: static;
  top: 0;
  padding-top: 0;
  margin: 0 -1px 0 0;
  padding: 0 10px;
  background: transparent;
  border: none; }
#content .tabs.secondary a:hover {
  text-decoration: underline;
}
#content .tabs.secondary .active a {
  background: #eee;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
}
  #content .tabs.secondary .active a:hover {
    text-decoration: none;
}

/* =========================================================================
 * 2. OVERLAY
 * ========================================================================= */

.overlay-element.overlay-active {
	padding:20px 0;
}